<template>
  <div>
    <!-- 测点信息 -->
    <el-form class="point-info" :model="form1" :inline="true">
      <p>测点信息</p>
      <el-form-item label="测点编号">{{ form1.code }}</el-form-item>
      <el-form-item label="监测类型">{{ form1.typeGroupName }}</el-form-item>
      <el-form-item label="监测指标">{{ form1.typeName }}</el-form-item>
      <el-form-item label="数据类型">{{ form1.staticDynamicSelected == 1 ? "静态效应" : form1.staticDynamicSelected == 2 ? "动态效应" : "振动响应" }}</el-form-item>
      <el-form-item label="位置描述" class="half-input">{{ form1.site }}</el-form-item>
      <el-form-item label="采样时间间隔" class="half-input">
        <span v-show="form1.frequency">{{ form1.frequency }}秒</span>
      </el-form-item>
      <el-form-item label="传感器型号">{{ form1.model }}</el-form-item>
      <el-form-item label="系数">{{ form1.coefficient }}</el-form-item>
      <el-form-item label="单位">{{ form1.unit }}</el-form-item>
      <el-form-item label="精度">{{ form1.precisions }}</el-form-item>
      <el-form-item label="通讯地址" class="long-input">{{ form1.serialNumber }}</el-form-item>
    </el-form>
    <div class="set-info">
      <!-- 时长设置 -->
      <p>设置信息</p>
      <section>
        <div class="left-title">时长设置</div>
        <el-form class="set-time" :model="form2" :inline="true">
          <el-form-item label="是否预警">
            <el-select v-model="form2.duration" placeholder="请选择">
              <el-option v-for="item in durationOptions" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <!-- 数据类型为静态时，展示时域统计的时间间隔（天），非静态，展示基线校正的时间间隔(秒) -->
          <!-- <el-form-item label="预警分析时长" v-if="form2.duration == 1">
           
            <el-input v-model="form3.period" disabled />
            <span class="unit">{{ form1.staticDynamicSelected == 1 ? "天" : "秒" }}</span>
          </el-form-item> -->
        </el-form>
      </section>
      <!-- 阈值设置 -->
      <section class="set-threshold" v-if="form2.duration == 1">
        <div class="left-title">阈值设置</div>
        <div class="form-list">
          <div class="form-title">
            <span>正向阈值</span>
            <span>负向阈值</span>
          </div>
          <el-form :model="form3" :inline="true" ref="form3" :rules="rule3">
            <!-- 正向阈值 -->
            <div class="limit-value">
              <div>
                <el-form-item label="轻度预警" prop="clower">
                  <el-input v-model="form3.clower" placeholder="输入阈值下限" />
                </el-form-item>
                <el-form-item label="≤ 监测值 &lt;" prop="cupper">
                  <el-input v-model="form3.cupper" placeholder="输入阈值上限" @input="getOrangeLower" />
                </el-form-item>
              </div>
              <div>
                <el-form-item label="中度预警">
                  <el-input v-model="form3.blower" disabled />
                </el-form-item>
                <el-form-item label="≤ 监测值 &lt;" prop="alower">
                  <el-input v-model="form3.bupper" placeholder="输入阈值上限" @input="getRedLower" />
                </el-form-item>
              </div>
              <div>
                <el-form-item label="重度预警">
                  <el-input v-model="form3.alower" disabled />
                </el-form-item>
                <el-form-item label="≤ 监测值" />
              </div>
            </div>
            <!-- 负向阈值 -->
            <div class="limit-value">
              <div>
                <el-form-item label="轻度预警" prop="flower">
                  <el-input v-model="form3.flower" placeholder="输入阈值下限" @input="getOrangeUpper" />
                </el-form-item>
                <el-form-item label="&lt; 监测值 ≤" prop="fupper">
                  <el-input v-model="form3.fupper" placeholder="输入阈值上限" />
                </el-form-item>
              </div>
              <div>
                <el-form-item label="中度预警" prop="elower">
                  <el-input v-model="form3.elower" placeholder="输入阈值下限" @input="getRedUpper" />
                </el-form-item>
                <el-form-item label="&lt; 监测值 ≤ ">
                  <el-input v-model="form3.eupper" disabled />
                </el-form-item>
              </div>
              <div>
                <el-form-item label="重度预警" />
                <el-form-item label="监测值 ≤" class="red-warning">
                  <el-input v-model="form3.dupper" disabled />
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
      </section>

      <section v-if="form2.duration == 1">
        <div class="left-title">阈值标识线</div>
        <el-form class="set-time" :inline="true">
          <el-form-item>
            <el-checkbox v-model="form4.levelFirst" true-label="1" false-label="0">轻度预警</el-checkbox>
            <el-checkbox v-model="form4.levelSecond" true-label="1" false-label="0">中度预警</el-checkbox>
            <el-checkbox v-model="form4.levelThird" true-label="1" false-label="0">重度预警</el-checkbox>
            <!-- <el-checkbox-group v-model="checkList">
              <el-checkbox label="1">轻度预警</el-checkbox>
              <el-checkbox label="2">中度预警</el-checkbox>
              <el-checkbox label="3">重度预警</el-checkbox>
            </el-checkbox-group> -->
          </el-form-item>
          <!-- 数据类型为静态时，展示时域统计的时间间隔（天），非静态，展示基线校正的时间间隔(秒) -->
          <!-- <el-form-item label="预警分析时长" v-if="form2.duration == 1">
           
            <el-input v-model="form3.period" disabled />
            <span class="unit">{{ form1.staticDynamicSelected == 1 ? "天" : "秒" }}</span>
          </el-form-item> -->
        </el-form>
      </section>
    </div>
    <div class="btns box around">
      <!-- <el-button @click="handleSave" :disabled="disabled">保存</el-button>
      <el-button @click="$router.go(-1)">返回</el-button> -->
      <div class="box">
        <div class="btnSelect1 mR20 pointer" @click="$router.go(-1)">返回</div>
        <div class="btnSelect2 pointer" @click="handleSave" :disabled="disabled">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var checkedForwardLowerLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值下限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else {
        if (parseInt(value) > parseInt(this.form3.cupper)) {
          //  parseInt
          callback("下限不能大于上限");
        } else callback();
      }
    };
    var checkedForwardYellowUpperLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值上限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else {
        if (parseInt(value) < parseInt(this.form3.clower)) {
          //parseInt
          callback("上限不能小于下限");
        } else callback();
      }
    };

    var checkedForwardOrangeUpperLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值上限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else {
        if (parseInt(value) < parseInt(this.form3.cupper)) {
          callback("上限不能小于下限");
        } else callback();
      }
    };

    var checkedNegativeYellowLowerLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值下限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else {
        if (!(Number(value) < Number(this.form3.fupper))) {
          // console.log('66666666666666666', value, this.form3.fupper);
          callback("下限必须小于上限");
        } else callback();
      }
    };
    var checkedNegativeYellowUpperLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值上限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else {
        if (!(Number(value) > Number(this.form3.flower))) {
          // console.log('77777777777', value, this.form3.flower);
          callback("上限必须大于下限");
        } else callback();
      }
    };
    var checkedNegativeOrangeLowerLimit = (rule, value, callback) => {
      // console.log('88888888888888', value, this.form3.flower);
      if (value === "") {
        callback("请输入阈值上限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else {
        if (!(Number(value) < Number(this.form3.flower))) {
          callback("下限必须小于上限");
        } else callback();
      }
    };
    return {
      form4: { levelFirst: 0, levelSecond: 0, levelThird: 0 },
      checkList: [],
      form1: {},
      targetId: "",
      disabled: false,
      form2: { duration: 0 },
      form3: {},
      durationOptions: [
        { id: 0, name: "不预警" },
        { id: 1, name: "预警" }
      ],
      rule3: {
        clower: [{ validator: checkedForwardLowerLimit, trigger: "blur" }],
        cupper: [{ validator: checkedForwardYellowUpperLimit, trigger: "blur" }],
        alower: [{ validator: checkedForwardOrangeUpperLimit, trigger: "blur" }],
        flower: [{ validator: checkedNegativeYellowLowerLimit, trigger: "blur" }],
        fupper: [{ validator: checkedNegativeYellowUpperLimit, trigger: "blur" }],
        elower: [{ validator: checkedNegativeOrangeLowerLimit, trigger: "blur" }]
      }
    };
  },
  mounted() {
    this.arr = JSON.parse(sessionStorage.getItem("multipleSelectionSingle"));
    this.targetId = this.$route.params.id;
    this.disabled = sessionStorage.getItem("isAdmin") == 2 ? true : false;
    this.getData();

    let arrSet = this.$store.state.breadcrumbList;
    if (arrSet.length <= 3) {
      arrSet.push({ name: "设置阈值" });
      this.$store.commit("getBreadcrumb", arr);
    }
  },
  methods: {
    getData() {
      this.$axios.get(`${this.baseURL}base/baseTarget/${this.targetId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          this.form1 = res.data;
        }
      });
      this.$axios.get(`${this.baseURL}setttings/settingsThresholdQuota/option/${this.targetId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          this.form2.duration = res.data.duration ? res.data.duration : 0;
          this.form3 = res.data;
          this.form4.levelFirst = res.data.levelFirst;
          this.form4.levelSecond = res.data.levelSecond;
          this.form4.levelThird = res.data.levelThird;
        }
      });
    },
    // 正向
    getOrangeLower(val) {
      this.form3.blower = val;
    },
    getRedLower(val) {
      this.form3.alower = val;
    },
    // 负向
    getOrangeUpper(val) {
      this.form3.eupper = val;
    },
    getRedUpper(val) {
      this.form3.dupper = val;
    },
    saveData() {
      this.form3.targetId = this.targetId;
      this.form3.duration = this.form2.duration;

      this.form3 = Object.assign(this.form3, this.form4);

      console.log("save", this.arr, this.form3);
      if (this.arr.length) {
        let arr1 = [];
        this.arr.forEach((item) => {
          arr1.push(item.id);
        });

        this.form3["targetIds"] = arr1.join(",");
        console.log("form", this.form3);
        this.$axios.post(`${this.baseURL}setttings/settingsThresholdQuota/save/batch`, this.$qs.stringify(this.form3)).then((res) => {
          console.log("res-保存1", res);

          res = res.data;
          if (res.errCode == 200) this.$router.go(-1);
        });
        return;
      }

      this.$axios.post(`${this.baseURL}setttings/settingsThresholdQuota/save`, this.$qs.stringify(this.form3)).then((res) => {
        res = res.data;
        console.log("save返回数据", res);
        if (res.errCode == 200) this.$router.go(-1);
      });
    },
    handleSave() {
      console.log("checkList", this.form4.levelThird);

      if (this.form2.duration == 1) {
        this.$refs["form3"].validate((valid) => {
          if (valid) this.saveData();
        });
      } else {
        this.saveData();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-select > .el-input {
  height: 32px;
}

::v-deep {
  .el-form-item__label {
    padding: 0;
    width: 152px;
    line-height: 52px;
    text-align: center;
    white-space: nowrap;
  }

  .point-info .el-form-item__label {
    background: #0c265a;
  }

  .el-form-item__content {
    flex: 1;
    color: #a8aaab;
    line-height: 52px;
    text-align: center;
  }

  .el-form-item__error {
    top: 40px;
  }

  .half-input {
    width: 50% !important;
  }

  .long-input {
    width: 100% !important;

    .el-form-item__content {
      width: 95% !important;
    }
  }

  .set-info {
    .el-form-item__label,
    .el-form-item__content {
      text-align: left;
      background: transparent;
    }
  }

  .set-threshold {
    .el-input__inner {
      padding: 0;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }
  }

  .is-disabled .el-input__inner {
    width: 120px;
    height: 30px !important;
    border: 1px solid #383e41 !important;
    background: rgba(56, 63, 65, 0.29) !important;
  }

  .set-time .is-disabled {
    width: 120px;
    margin-right: 5px;
  }

  .red-warning .el-form-item__label {
    text-align: right;
  }
}

p {
  color: #fff;
  line-height: 40px;
  text-align: center;
}

.set-info {
  section {
    display: flex;
    border-top: 1px solid #2c3134;

    .left-title {
      width: 152px;
      display: flex;
      color: #fff;
      line-height: 52px;
      white-space: nowrap;
      align-items: center;
      justify-content: center;
      background: #0c265a;
    }
  }

  .set-time {
    flex: 1;
    padding-left: 2%;

    .el-form-item:nth-child(2) {
      margin-left: 15px;
    }
  }
}

.point-info {
  .el-form-item {
    display: inline-flex;
    border-top: 1px solid #2c3134;
  }

  .el-form-item:nth-child(-n + 5),
  .el-form-item:nth-child(n + 7) {
    width: 25%;
  }
}

.point-info,
.set-info {
  margin: 4px;
  border-radius: 8px;
  margin-bottom: 26px;
  border: 1px solid #5e5e5f;
  box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);

  .el-form-item {
    margin: 0;
  }
}

.set-threshold {
  .form-title {
    margin: 20px 0;

    span {
      width: 50%;
      display: inline-block;
    }
  }

  .form-list {
    flex: 1;
    padding: 0 0 23px 2%;

    .el-form {
      flex: 1;
      margin: 0;
      padding: 0;
      border: none;
      display: flex;
      flex-wrap: wrap;
      box-shadow: none;

      .el-form-item {
        border: none;
        height: 52px;
      }
    }

    .limit-value {
      width: 50%;
    }
  }
}

.btns {
  margin-top: 44px;
  text-align: center;

  .el-button {
    background: #1e2529;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }

  .el-button:last-child {
    margin-left: 50px;
    // background: #1E2529;
  }
}

@media screen and (min-width: 1750px) {
  .limit-value ::v-deep {
    .el-form-item__label {
      width: 90px;
    }

    .el-form-item {
      width: 258px;
      display: inline-flex;

      .el-form-item__label {
        margin-right: 20px;
      }

      .el-input,
      .el-input__inner {
        width: 130px;
      }
    }
  }
}

@media screen and (max-width: 1750px) {
  ::v-deep {
    .el-form:first-child {
      .el-form-item__label {
        min-width: 110px;
        white-space: nowrap;
      }
    }

    .limit-value {
      .el-form-item {
        width: 220px;

        .el-form-item__label {
          width: 95px;
          margin-right: 10px !important;
        }

        .el-input,
        .el-input__inner {
          width: 115px;
        }
      }

      .el-form-item:nth-child(odd) {
        width: 206px;

        .el-form-item__label {
          width: 72px !important;
        }
      }
    }
  }
}
</style>
